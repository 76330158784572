import React from 'react';
import SignInButton from './components/SignInButton';
import logo from './assets/Happy Calories Logo Edited.svg';
import herodesktop from './assets/Desktop Hero Image Component Edited.svg'
import './App.css';

const VoiceAnimation = () => (
  <span className="mx-3 inline-flex items-center">
    <svg viewBox="0 0 24 24" className="w-8 h-8" style={{ marginTop: '-2px' }}>
      <rect x="2" y="6" width="1" height="12" className="fill-amber-600">
        <animate attributeName="height" values="6;16;6" dur="1s" repeatCount="indefinite"/>
        <animate attributeName="y" values="9;4;9" dur="1s" repeatCount="indefinite"/>
      </rect>
      <rect x="5" y="6" width="1" height="12" className="fill-amber-600">
        <animate attributeName="height" values="6;16;6" dur="1s" begin="0.1s" repeatCount="indefinite"/>
        <animate attributeName="y" values="9;4;9" dur="1s" begin="0.1s" repeatCount="indefinite"/>
      </rect>
      <rect x="8" y="6" width="1" height="12" className="fill-amber-600">
        <animate attributeName="height" values="6;16;6" dur="1s" begin="0.2s" repeatCount="indefinite"/>
        <animate attributeName="y" values="9;4;9" dur="1s" begin="0.2s" repeatCount="indefinite"/>
      </rect>
      <rect x="11" y="6" width="1" height="12" className="fill-amber-600">
        <animate attributeName="height" values="6;16;6" dur="1s" begin="0.3s" repeatCount="indefinite"/>
        <animate attributeName="y" values="9;4;9" dur="1s" begin="0.3s" repeatCount="indefinite"/>
      </rect>
      <rect x="14" y="6" width="1" height="12" className="fill-amber-600">
        <animate attributeName="height" values="6;16;6" dur="1s" begin="0.4s" repeatCount="indefinite"/>
        <animate attributeName="y" values="9;4;9" dur="1s" begin="0.4s" repeatCount="indefinite"/>
      </rect>
      <rect x="17" y="6" width="1" height="12" className="fill-amber-600">
        <animate attributeName="height" values="6;16;6" dur="1s" begin="0.5s" repeatCount="indefinite"/>
        <animate attributeName="y" values="9;4;9" dur="1s" begin="0.5s" repeatCount="indefinite"/>
      </rect>
      <rect x="20" y="6" width="1" height="12" className="fill-amber-600">
        <animate attributeName="height" values="6;16;6" dur="1s" begin="0.6s" repeatCount="indefinite"/>
        <animate attributeName="y" values="9;4;9" dur="1s" begin="0.6s" repeatCount="indefinite"/>
      </rect>
      <rect x="23" y="6" width="1" height="12" className="fill-amber-600">
        <animate attributeName="height" values="6;16;6" dur="1s" begin="0.7s" repeatCount="indefinite"/>
        <animate attributeName="y" values="9;4;9" dur="1s" begin="0.7s" repeatCount="indefinite"/>
      </rect>
    </svg>
  </span>
);

function App() {
  return (
    <div className="relative min-h-screen bg-gray-50">
      {/* Mobile Layout */}
      <div className="md:hidden flex flex-col min-h-screen bg-white">
        {/* Logo */}
        <div className="w-full pt-8 px-6">
          <img src={logo} alt="Happy Calories" className="h-20 mx-auto w-auto" />
        </div>

        {/* Title */}
        <div className="text-center px-6 mt-8">
          <h1 className="text-3xl leading-tight inline-flex items-center justify-center flex-wrap">
            <span className="inline-flex items-center">AI chat<VoiceAnimation /></span>
            <span className="block">for high protein</span>
            <span className="block">vegetarian</span>
            <span className="block">recipes</span>
          </h1>
        </div>

        {/* Sign Up Button */}
        <div className="text-center mt-12 mb-8 px-6">
          <SignInButton className="bg-purple-600 w-full text-white px-16 py-4 rounded-full text-xl font-medium hover:bg-purple-700 transition-colors disabled:opacity-75" />
        </div>

        {/* Hero Image */}
        <div className="px-6 mb-8">
          <div className="relative w-full aspect-[4/5] rounded-3xl overflow-hidden">
            <img src={require('./assets/Mobile Hero Image Component.png')} alt="Happy person enjoying food" className="w-full h-full object-cover" />
          </div>
        </div>

        {/* Stats and Powered By */}
        <div className="px-8 flex justify-between items-start">
          <div className="flex items-center">
            <div className="bg-green-100 p-3 rounded-full mr-3">
              <svg className="w-4 h-4 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 10l7-7m0 0l7 7m-7-7v18"/>
              </svg>
            </div>
            <div>
              <div className="text-3xl font-bold">74K</div>
              <div className="text-gray-600 text-base">Happy followers</div>
            </div>
          </div>
          
          <div className="flex items-center">
            <div className="bg-amber-50 p-2 rounded-full mr-3">
              <svg className="w-5 h-5 text-amber-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"/>
              </svg>
            </div>
            <div className="text-base">
              Powered by<br />
              Azure Open AI
            </div>
          </div>
        </div>

        {/* Instagram Footer */}
        <div className="mt-auto">
          <div className="relative pt-12 pb-8 bg-gradient-to-b from-transparent to-amber-50/30" style={{
            borderTopLeftRadius: '2rem',
            borderTopRightRadius: '2rem'
          }}>
            <a href="https://www.instagram.com/paulaasra/" target="_blank" rel="noopener noreferrer" className="block">
              <img src={require('./assets/Follow on instagram.png')} alt="Follow us on Instagram" className="h-12 mx-auto" />
            </a>
          </div>
        </div>
      </div>

      {/* Desktop Layout */}
      <div className="hidden md:block relative min-h-screen bg-gray-50">
        {/* Header with centered logo and Instagram button */}
        <div className="container mx-auto px-6 pt-16">
          <div className="flex justify-between items-center">
            <div className="w-32 invisible">{/* Spacer */}</div>
            <img src={logo} alt="Happy Calories" className="h-16 w-auto" />
            <a href="https://www.instagram.com/paulaasra/" target="_blank" rel="noopener noreferrer">
              <img src={require('./assets/Follow on instagram.png')} alt="Follow us on Instagram" className="h-8" />
            </a>
          </div>
        </div>

        {/* Main Content */}
        <div className="container mx-auto px-6 pt-16 flex flex-col items-center">
          {/* Main Title */}
          <h1 className="text-4xl font-normal text-center max-w-3xl mb-16 inline-flex items-center justify-center">
            <span className="inline-flex items-center">AI chat<VoiceAnimation /></span>
            for high protein vegetarian recipes
          </h1>

          {/* Hero Section */}
          <div className="relative w-full max-w-5xl">
            <div className="flex items-center justify-center relative">
              {/* Left - 74K Stats */}
              <div className="absolute left-[-120px] -translate-y-1/2 left-image-text-section-followers">
                <div className="flex flex-col">
                  <div className="flex items-center space-x-2">
                    <div className="bg-green-200 p-2 rounded-full">
                      <svg className="w-5 h-5 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 10l7-7m0 0l7 7m-7-7v18"/>
                      </svg>
                    </div>
                    <span className="text-4xl font-bold">74K</span>
                  </div>
                  <span className="text-gray-600 mt-1">Happy followers</span>
                </div>
              </div>

              {/* Center - Hero Image with Lines */}
              <div className="relative flex items-center">
                {/* Left connecting line */}
                {/*<div className="w-16 h-[1px] bg-amber-600"></div>*/}
                
                {/* Hero Image */}
                <div className="w-[500px] rounded-2xl overflow-hidden">
                  <img 
                    src={herodesktop} 
                    alt="Happy person enjoying food" 
                    className="w-full h-full object-cover"
                  />
                </div>

                {/* Right connecting line */}
                {/*<div className="w-16 h-[1px] bg-amber-600"></div>*/}
              </div>


             {/* Replace just the Right Side Content section of your current code with this: */}

              {/* Right Side Content */}
              <div className="absolute right-image-text-selection-azure"> 
                {/* Powered by Azure */}
                <div className="flex items-center mb-8">
                  <div className="bg-amber-50/50 p-2 rounded-full thunder-background">
                    <svg className="w-5 h-5 text-amber-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"/>
                    </svg>
                  </div>
                  <div className="ml-3">
                    <span>Powered by Azure Open AI</span>
                  </div>
                </div>
                
                {/* Sign Up Button or Signed In Text */}
                <div className="signup-button-container">
                  <SignInButton 
                    className="bg-purple-600 text-white px-12 py-3 rounded-full text-xl hover:bg-purple-700 transition-colors"
                  />
                </div>
              </div>
              {/* Sign Up Button */}
            </div>
          </div>
        </div>

        {/* Curved bottom background */}
        <div className="absolute bottom-0 left-0 right-0 h-32 bg-amber-50/20" style={{
          borderTopLeftRadius: '100% 100%',
          borderTopRightRadius: '100% 100%',
          transform: 'scaleX(1.5)',
        }} />
      </div>
    </div>
  );
}

export default App;