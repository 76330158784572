// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDhcu-FE0Pc-4DBy2NHdcf0E-9ejDEY0BE",
  authDomain: "happycalories.firebaseapp.com",
  projectId: "happycalories",
  storageBucket: "happycalories.firebasestorage.app",
  messagingSenderId: "145098783512",
  appId: "1:145098783512:web:a4537ae6bab26522f8bc6d",
  measurementId: "G-7ZEHM6DVGC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication
const auth = getAuth(app);

// Initialize Firestore
const db = getFirestore(app);

// Configure persistence and other settings
if (typeof window !== 'undefined') {
  // Set CORS headers for development
  if (process.env.NODE_ENV === 'development') {
    const corsConfig = {
      'Cross-Origin-Opener-Policy': 'same-origin-allow-popups',
      'Cross-Origin-Embedder-Policy': 'require-corp'
    };
    Object.entries(corsConfig).forEach(([key, value]) => {
      document.cookie = `${key}=${value}; path=/`;
    });
  }
}

export { auth, db };