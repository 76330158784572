import React, { useState, useEffect } from 'react';
import { signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';

export default function SignInButton({ className }) {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const signInWithGoogle = async () => {
    setIsLoading(true);
    setMessage('');
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/userinfo.email');
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
    provider.setCustomParameters({
      prompt: 'select_account'
    });

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      try {
        const docRef = doc(db, 'waitlist', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setMessage("You're already on our waitlist! We'll notify you when we launch.");
        } else {
          await setDoc(docRef, {
            email: user.email,
            displayName: user.displayName,
            joinedAt: new Date(),
            lastUpdated: new Date(),
          }, { merge: true });
          setMessage("Thank you for joining our waitlist! We'll notify you when we launch.");
        }
      } catch (dbError) {
        console.error('Database error:', dbError);
        setMessage('There was an error processing your signup. Please try again.');
      }
    } catch (error) {
      console.error('Error signing in with Google:', error);
      if (error.code === 'auth/popup-closed-by-user') {
        setMessage('Sign-in cancelled. Please try again.');
      } else if (error.code === 'auth/popup-blocked') {
        setMessage('Pop-up was blocked by your browser. Please enable pop-ups and try again.');
      } else {
        setMessage('Failed to sign in. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (user) {
    return (
      <div className="text-center">
        <div className="text-sm text-gray-600">
          Hi {user.email}. Thanks for joining the waitlist. We'll reach out soon
        </div>
        {message && (
          <div className="mt-2 text-sm text-gray-600">
            {message}
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <button
        onClick={signInWithGoogle}
        disabled={isLoading}
        className={`${className} whitespace-nowrap`}
      >
        {isLoading ? 'Please wait...' : 'Sign Up'}
      </button>
      {message && (
        <div className="mt-2 text-sm text-gray-600 text-center">
          {message}
        </div>
      )}
    </div>
  );
}